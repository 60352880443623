import React from "react";
import { Query } from "react-apollo";

import { GET_NETWORK_STATUS } from "../query/summary";

class NetworkStatusChecker extends React.Component {
  constructor(props) {
    super(props);

    const network = props.network;
    const networkId = network.networkId;
    const publisherId = props.publisherId;
    this.state = {
      network,
      networkStatus: props.network.status,
      networkId,
      publisherId,
    };
  }

  render() {
    const variables = {
      filter: {
        gamAccountId: this.props.gamAccountId,
        networkIds: [this.state.networkId],
      },
      pubId: this.state.publisherId,
    };
    const pollingInterval = 3 * 1000; // 3 seconds

    return (
      <Query
        query={GET_NETWORK_STATUS}
        variables={variables}
        fetchPolicy={"network-only"}
        pollInterval={pollingInterval}
      >
        {({ loading, error, data, stopPolling }) => {
          let newStatus = null;

          if (data) {
            const status = _.get(data, "gamNetworks.edges[0].node.status");
            newStatus = status;

            if (status === "PROCESSING") {
              // continue polling
            } else {
              // RUNNING, PAUSED, ARCHIVED, FAILED, PROBING
              stopPolling();
            }

            return this.props.children({
              loading,
              error,
              status: newStatus || this.state.networkStatus,
            });
          }
        }}
      </Query>
    );
  }
}

export default NetworkStatusChecker;
