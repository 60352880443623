import React from "react";
import { Query, Mutation } from "react-apollo";

import CommonStyle from "common/styles/common.css";
import FormStyle from "common/styles/forms.css";
import YbTooltip from "common/components/yb-tooltip";
import YbLoading from "common/components/yb-loading";
import YbModal from "common/components/yb-modal";

import AdSensePaymentSetting from "common/images/adsense_payment_setting.png";
import AdSensePaymentProfileCountry from "common/images/adsense_payment_profile_country.png";

import { GAM_NETWORK_TYPE } from "../../_newapp/common/constants/common";

import Styles from "../styles/summary.css";
import CountrySelector from "../../subscription-and-billing/components/country-selector";
import {
  GET_CANDIDATES,
  BOOST_NETWORK,
  GET_NETWORK_STATUS,
} from "../query/summary";
import VerifyNetworkButton from "./verify-network-button";
import YbInfoTooltip from "./yb-info-tooltip";
import GAM360AccessInstructionPic from "common/images/gam360-access-instruction.jpg";

const PROFILE_TYPE = {
  BASIC_FREE: "AD_MANAGER",
  BASIC_INVOICED: "INVOICED_AD_MANAGER",
  GAM360_BASIC: "AD_MANAGER_360_BASIC",
  GAM360_ALL_ENTITY: "AD_MANAGER_360_ALL_ENTITY",
  GAM360_TEAM: "AD_MANAGER_360_CUSTOM_TEAM",
};

const PRODUCT = {
  DT: "DT",
  // CSTV1: "", // don't show
  CSTV2: "CSTV2",
  CSTV2NP: "CSTV2NP",
};

class YbNetworkCandidates extends React.Component {
  constructor(props) {
    super(props);

    this.onHideModal = this.onHideModal.bind(this);

    this.state = {
      boost: null,
      network: null,

      profileType: PROFILE_TYPE.BASIC_FREE,
      teamId: "",
      geoCountryId: null,
      product: PRODUCT.CSTV2, // default

      submitting: false,
      reloadExternalSource: true,
    };

    this.reloadExternalSource = this.reloadExternalSource.bind(this);
    this.renderPollingNetworkStatus =
      this.renderPollingNetworkStatus.bind(this);
    this.handleProfileTypeChange = this.handleProfileTypeChange.bind(this);
    this.handleCountrySelect = this.handleCountrySelect.bind(this);
    this.onTeamIdChanged = this.onTeamIdChanged.bind(this);
    this.handleProductChange = this.handleProductChange.bind(this);
  }

  onTeamIdChanged(e) {
    this.setState({
      teamId: e.target.value,
    });
  }

  handleCountrySelect(geoCountryId) {
    this.setState({
      geoCountryId,
    });
  }

  handleProfileTypeChange(e) {
    this.setState({
      profileType: e.target.value,
      teamId: "",
    });
  }

  handleProductChange(e) {
    this.setState({
      product: e.target.value,
    });
  }

  reloadExternalSource(event) {
    this.setState({
      reloadExternalSource: true,
      network: null,
      profileType: PROFILE_TYPE.BASIC_FREE,
      teamId: "",
      geoCountryId: null,
    });

    event.preventDefault();
  }

  boostNetwork(boost, network) {
    // cannot select network when already submitting a selected network
    if (this.state.submitting) return;
    this.setState({
      boost: boost,
      network: network,

      // clear profile type
      profileType: PROFILE_TYPE.BASIC_FREE,
      teamId: "",
      geoCountryId: null,
    });
  }

  onSubmit() {
    var boost = this.state.boost;
    var network = this.state.network;
    var account = this.props.account;
    // Add teamId if profileType is TEAM
    var teamId = this.state.teamId;
    var profileType = this.state.profileType;
    var gamNetworkType = GAM_NETWORK_TYPE[profileType];
    var geoCountryId = this.state.geoCountryId;
    var product = this.state.product;

    var boostParams = {
      variables: {
        input: {
          name: network.name,
          gamAccountId: account.accountId,
          code: network.code,
          resources: "{}",
          gamNetworkType,
          geoCountryId,
          product,
        },
        pubId: this.props.publisherId,
      },
    };
    if (teamId.length > 0) {
      boostParams.variables.input.teamId = teamId;
    }

    // console.log(boostParams);
    boost(boostParams);

    this.setState({
      submitting: true,
    });
  }

  onHideModal(networkRefetch, data) {
    this.setState({
      boost: null,
      network: null,
      teamId: "",
      profileType: PROFILE_TYPE.BASIC_FREE,
      geoCountryId: null,
      product: PRODUCT.CSTV2,
    });

    if (networkRefetch) {
      setTimeout(networkRefetch, 0);
    }

    if (this.props.callback) {
      setTimeout(() => {
        this.props.callback(!!networkRefetch, data);
      }, 0);
    }
  }

  renderPollingNetworkStatus(candidate, networkId, hideAndRefetch) {
    const account = this.props.account;

    const variables = {
      filter: {
        gamAccountId: account.accountId,
        networkIds: [networkId],
      },
      pubId: this.props.publisherId,
    };
    const pollingInterval = 3000; // 3 seconds

    return (
      <Query
        query={GET_NETWORK_STATUS}
        variables={variables}
        fetchPolicy={"network-only"}
        pollInterval={pollingInterval}
      >
        {({ loading, error, data, stopPolling, refetch }) => {
          if (loading) {
            return (
              <span className={`${Styles.titleWrapper} ${Styles.errorMsg}`}>
                <YbLoading isWhiteText={true} />
              </span>
            );
          }

          if (error) {
            return (
              <span
                className={`${Styles.titleWrapper} ${Styles.errorMsg} text-danger`}
              >
                <span>
                  {i18n`There was an error trying to connect to`}{" "}
                  {candidate.name}...
                </span>
              </span>
            );
          }

          if (data) {
            const status = _.get(data, "gamNetworks.edges[0].node.status");
            if (status === "PROCESSING") {
              // continue polling
              return (
                <span className={`${Styles.titleWrapper} ${Styles.errorMsg}`}>
                  <YbLoading isWhiteText={true} />
                </span>
              );
            } else {
              // RUNNING, PAUSED, ARCHIVED, FAILED, PROBING
              stopPolling();
              hideAndRefetch();
              return <div />;
            }
          }
        }}
      </Query>
    );
  }

  getCandidates(data) {
    if (!data.extGamNetworks) {
      return [];
    }
    return data.extGamNetworks.filter((network) => {
      return !data.gamNetworks.edges.find(
        (gamNetwork) => gamNetwork.node.code === network.code
      );
    });
  }

  renderCandidate(
    inModal,
    candidate,
    networkRefetch,
    refetch,
    boost,
    { loading, error, data }
  ) {
    var isCandidate = false;
    var network = this.state.network;

    if (loading) {
      return (
        <div className={Styles.networkCandidate}>
          <div
            className={`${Styles.titleWrapper} ${CommonStyle.textAlignCenter}`}
          >
            <span className={`${Styles.titleWrapper} ${Styles.errorMsg}`}>
              <YbLoading isWhiteText={true} />
            </span>
          </div>
        </div>
      );
    }

    if (error) {
      setTimeout(() => {
        if (
          error.message.match(/PERMISSION\_DENIED/) &&
          this.props.permissionDeniedCallback
        ) {
          this.props.permissionDeniedCallback(candidate);
        }

        if (this.state.network) {
          this.setState({
            submitting: false,
            network: null,
            reloadExternalSource: false,
          });
        }
      }, 0);

      return (
        <div className={Styles.networkCandidate}>
          <div className={Styles.titleWrapper}>
            <span
              className={`${Styles.titleWrapper} ${Styles.errorMsg} text-danger`}
            >
              <span>
                {i18n`There was an error trying to boost`} {candidate.name}...
              </span>
              <br />
              <span>{i18n`Please try again later!`}</span>
            </span>
          </div>
        </div>
      );
    }

    if (data) {
      const onHideModal = this.onHideModal;
      function hideAndRefetch() {
        refetch();
        setTimeout(() => {
          onHideModal(networkRefetch, data);
        }, 0);
      }

      // * Check this network's status
      // - if status is ok for onboarding => refetch, hide modal
      // - if status is processing => polling until network status is not processing
      // - if status is error => show error message

      const networkId = _.get(data, "createGamNetwork.gamNetwork.networkId");

      return (
        <div className={Styles.networkCandidate}>
          <div
            className={`${Styles.titleWrapper} ${CommonStyle.textAlignCenter}`}
          >
            {this.renderPollingNetworkStatus(
              candidate,
              networkId,
              hideAndRefetch
            )}
          </div>
        </div>
      );
    }

    isCandidate = inModal && network && network.code === candidate.code;
    return (
      <div
        className={`${Styles.networkCandidate} ${
          isCandidate ? Styles.selected : ""
        }`}
        onClick={this.boostNetwork.bind(this, boost, candidate)}
      >
        <div className={Styles.titleWrapper}>
          {!inModal && (
            <span className={Styles.caret}>
              <i className="fa fa-plus" /> {i18n`ADD_NETWORK`}:
            </span>
          )}
          {isCandidate && (
            <span>
              <i className="fa fa-check" /> {candidate.name}
            </span>
          )}
          {!isCandidate && <span>{candidate.name}</span>}
        </div>
      </div>
    );
  }

  renderCandidates(inModal) {
    var networkRefetch = this.props.refetch;
    var networks = this.props.networks;
    var account = this.props.account;
    var showModal = !!this.state.boost;
    var network = this.state.network;
    var variables = {
      filter: {
        gamAccountId: account.accountId,
        reload: this.state.reloadExternalSource,
      },
      pubId: this.props.publisherId,
    };

    return (
      <Query
        query={GET_CANDIDATES}
        variables={variables}
        fetchPolicy={"network-only"}
      >
        {({ loading, error, data, refetch }) => {
          var candidates = [];
          if (loading) {
            return inModal ? <YbLoading isWhiteText={true} /> : <span />;
          }

          if (error) {
            return (
              <div className={Styles.networkCandidate}>
                <div
                  className={`${Styles.titleWrapper} ${Styles.errorMsg} text-danger`}
                >
                  {i18n`ERROR_LOADING_NETWORK_CANDIDATES_ELLIPSIS`}
                </div>
              </div>
            );
          }

          candidates = this.getCandidates(data);

          if (this.state.reloadExternalSource) {
            setTimeout(() => {
              this.setState({
                reloadExternalSource: false,
              });
            }, 0);
          }

          if (this.props.loaded) {
            setTimeout(() => {
              this.props.loaded(candidates.length);
            }, 0);
          }

          if (!candidates.length && !networks.length) {
            return (
              <div
                className={`${CommonStyle.textView} ${CommonStyle.textAlignCenter}`}
              >
                {i18n`WE_ARE_NOT_ABLE_TO_RETRIEVE_NETWORK_CANDIDATES_FOR_THIS_ACCOUNT_EM`}
                <br />
                {i18n`PLEASE_CHECK_YOUR_GAM_ACCOUNTS_CONFIGURATION_DOT`}
              </div>
            );
          }

          if (inModal && !candidates.length) {
            return (
              <div
                className={`${CommonStyle.textView} ${CommonStyle.textAlignCenter}`}
              >
                {i18n`NO_AVAILABLE_NETWORKS_EM`}
              </div>
            );
          }

          if (!inModal) {
            return <span />;
          }

          return (
            <div>
              {candidates.map((candidate, candidateIndex) => {
                var alreadyBoosted = networks.find((network) => {
                  return network.code === candidate.code;
                });

                if (alreadyBoosted) {
                  // return <div key={candidateIndex} />;
                  return (
                    <div
                      key={candidateIndex}
                      style={{
                        color: "gray",
                        paddingTop: "8px",
                        paddingBottom: "8px",
                      }}
                    >
                      {candidate.name} (Already Boosted)
                    </div>
                  );
                }

                return (
                  <Mutation mutation={BOOST_NETWORK} key={candidateIndex}>
                    {this.renderCandidate.bind(
                      this,
                      inModal,
                      candidate,
                      networkRefetch,
                      refetch
                    )}
                  </Mutation>
                );
              })}
            </div>
          );
        }}
      </Query>
    );
  }

  render() {
    var network = this.state.network;
    var profileType = this.state.profileType;
    var teamId = this.state.teamId;
    var product = this.state.product;

    var showModal = !!this.state.boost;
    var inModal = this.props.inModal;
    var account = this.props.account;
    var submitting = this.state.submitting;
    var reloadExternalSource = this.state.reloadExternalSource;
    var props = {};

    if (submitting) {
      props.disabled = true;
    }

    var shouldDisableSubmit = submitting || !network;
    if (network && profileType === PROFILE_TYPE.GAM360_TEAM) {
      shouldDisableSubmit = teamId.length === 0;
    } else {
      shouldDisableSubmit = false;
    }
    shouldDisableSubmit = this.state.geoCountryId === null;

    return (
      <div>
        {this.renderCandidates()}
        <YbModal
          show={inModal}
          onHide={this.onHideModal.bind(this)}
          onSubmit={this.onSubmit.bind(this)}
          title={
            <span>
              {account.name}
              <YbTooltip
                message={i18n`SYNCHRONIZE_YOUR_NETWORKS_WITH_GOOGLE_AD_MANAGER`}
                position="bottom"
              >
                <button
                  {...props}
                  className={CommonStyle.button}
                  onClick={this.reloadExternalSource}
                >
                  <i className="fa fa-refresh" />
                </button>
              </YbTooltip>
            </span>
          }
          successText={i18n`ADD_NETWORK`}
          disableSubmit={shouldDisableSubmit}
          disabled={submitting || reloadExternalSource}
        >
          <span className={CommonStyle.textView}>
            <div
              className={`${CommonStyle.textAlignLeft}`}
              style={{
                fontWeight: "bold",
                fontSize: "larger",
              }}
            >
              1. Select Network:
            </div>

            <div className={`${CommonStyle.textAlignCenter} ${Styles.inModal}`}>
              {this.renderCandidates(inModal)}
            </div>
          </span>

          {/* Add  Netework Profile Setting Selection
            1. GAM Small Business - Free
            2. GAM Small Business - Invoiced
            3. GAM 360 Basic (Not All Entity)
            4. GAM 360 with All Entity
            5. GAM360 with custom team ID
          */}
          {network && (
            <div
              style={{
                borderTop: "1px solid white",
                marginTop: "16px",
                paddingTop: "16px",
              }}
            >
              <span className={CommonStyle.textView}>
                <div
                  className={`${CommonStyle.textAlignLeft}`}
                  style={{
                    fontWeight: "bold",
                    fontSize: "larger",
                  }}
                >
                  2. Select GAM Profile Setting:{" "}
                  <YbInfoTooltip metricKey="create-network-2" place={"top"}>
                    <div style={{ fontSize: "24px" }}>GAM Profile Setting:</div>
                    <hr style={{ marginTop: "0px" }}></hr>
                    <ul>
                      <li>
                        <b>GAM Small Business - Free</b>: Free version
                      </li>
                      <li>
                        <b>GAM Small Business - Invoiced</b>: If you have
                        billing section in your GAM console
                      </li>

                      <li>
                        <b>GAM 360 - Basic</b>: Not all entity
                      </li>
                      <li>
                        <b>GAM 360 - All Entity</b>: All Entity permission
                        enabled
                      </li>
                      <li>
                        <b>GAM 360 - Custom Team Access</b>: Team access
                      </li>
                    </ul>

                    <img src={GAM360AccessInstructionPic} width="600px"></img>
                  </YbInfoTooltip>
                </div>

                <div
                  className={`${CommonStyle.textAlignCenter} ${Styles.inModal}`}
                >
                  <div>
                    <div className={CommonStyle.textAlignLeft}>
                      <label className={`${FormStyle.inputRadio}`}>
                        <input
                          type="radio"
                          value={PROFILE_TYPE.BASIC_FREE}
                          name="radio"
                          onChange={this.handleProfileTypeChange}
                          checked={profileType === PROFILE_TYPE.BASIC_FREE}
                          disabled={submitting}
                        />{" "}
                        GAM Small Business - Free
                      </label>
                      <br />

                      <label className={`${FormStyle.inputRadio}`}>
                        <input
                          type="radio"
                          value={PROFILE_TYPE.BASIC_INVOICED}
                          name="radio"
                          onChange={this.handleProfileTypeChange}
                          checked={profileType === PROFILE_TYPE.BASIC_INVOICED}
                          disabled={submitting}
                        />{" "}
                        GAM Small Business - Invoiced
                      </label>
                      <br />

                      <label className={`${FormStyle.inputRadio}`}>
                        <input
                          type="radio"
                          value={PROFILE_TYPE.GAM360_BASIC}
                          name="radio"
                          onChange={this.handleProfileTypeChange}
                          checked={profileType === PROFILE_TYPE.GAM360_BASIC}
                          disabled={submitting}
                        />{" "}
                        GAM 360 - Basic
                      </label>
                      <br />

                      <label className={`${FormStyle.inputRadio}`}>
                        <input
                          type="radio"
                          value={PROFILE_TYPE.GAM360_ALL_ENTITY}
                          name="radio"
                          onChange={this.handleProfileTypeChange}
                          checked={
                            profileType === PROFILE_TYPE.GAM360_ALL_ENTITY
                          }
                          disabled={submitting}
                        />{" "}
                        GAM 360 - All Entity
                      </label>
                      <br />

                      <label className={`${FormStyle.inputRadio}`}>
                        <input
                          type="radio"
                          value={PROFILE_TYPE.GAM360_TEAM}
                          name="radio"
                          onChange={this.handleProfileTypeChange}
                          checked={profileType === PROFILE_TYPE.GAM360_TEAM}
                          disabled={submitting}
                        />{" "}
                        GAM 360 - Custom Team Access
                        {profileType === PROFILE_TYPE.GAM360_TEAM && (
                          <div className={FormStyle.inputGroup}>
                            <div
                              className={`${FormStyle.form} ${FormStyle.formNoPadding}`}
                            >
                              <input
                                value={teamId}
                                type="number"
                                maxLength="32"
                                required
                                disabled={submitting}
                                placeholder={"Type in your Team ID"}
                                onChange={this.onTeamIdChanged}
                              />
                            </div>
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          )}

          {network && (
            <div
              style={{
                borderTop: "1px solid white",
                marginTop: "16px",
                paddingTop: "16px",
              }}
            >
              <span className={CommonStyle.textView}>
                <div
                  className={`${CommonStyle.textAlignLeft}`}
                  style={{
                    fontWeight: "bold",
                    fontSize: "larger",
                  }}
                >
                  3. Select Payment Country:{" "}
                  <YbInfoTooltip metricKey="create-network-2" place={"top"}>
                    <div style={{ fontSize: "24px" }}>
                      How to check Payment Country:
                    </div>
                    <hr style={{ marginTop: "0px" }}></hr>
                    {/* <p>You can check your Google AdSense account configuration: </p> */}
                    <p>1. Login to your Google AdSense console </p>
                    <p>
                      2. Click on <b>"Payments"</b> on the left panel{" "}
                    </p>
                    <p>
                      3. Under the <b>"Settings"</b> section, click on{" "}
                      <b>"MANAGE SETTINGS"</b>
                    </p>
                    <img
                      src={AdSensePaymentSetting}
                      width="600px"
                      className="mb-4"
                    />
                    <p>
                      4. Under <b>"Payments profile"</b>, check your{" "}
                      <b>"Country/Region"</b>
                    </p>
                    <img
                      src={AdSensePaymentProfileCountry}
                      width="600px"
                      className="mb-4"
                    />
                  </YbInfoTooltip>
                </div>

                <div style={{ color: "#0F182C" }}>
                  <CountrySelector
                    geoCountryId={this.state.geoCountryId}
                    handleChange={(geoCountryId) =>
                      this.handleCountrySelect(geoCountryId)
                    }
                    menuPlacement={"top"}
                    placeholder={"Select payment country"}
                    isDisabled={submitting}
                  ></CountrySelector>
                </div>
              </span>
            </div>
          )}

          {/* Add Product selection
            1. DT: v1.0
            2. CSTV1: Don't show!
            3. CSTV2: v2.0 with codeless explorer
            4. CSTV2NP: v2.0 without codeless explorer
          */}
          {network && (
            <div
              style={{
                borderTop: "1px solid white",
                marginTop: "16px",
                paddingTop: "16px",
              }}
            >
              <span className={CommonStyle.textView}>
                <div
                  className={`${CommonStyle.textAlignLeft}`}
                  style={{
                    fontWeight: "bold",
                    fontSize: "larger",
                  }}
                >
                  4. Select Product Version:{" "}
                </div>

                <div
                  className={`${CommonStyle.textAlignCenter} ${Styles.inModal}`}
                >
                  <div>
                    <div className={CommonStyle.textAlignLeft}>
                      <label className={`${FormStyle.inputRadio}`}>
                        <input
                          type="radio"
                          value={PRODUCT.DT}
                          name="radio_product"
                          onChange={this.handleProductChange}
                          checked={product === PRODUCT.DT}
                          disabled={submitting}
                        />{" "}
                        v1.0
                      </label>
                      <br />

                      <label className={`${FormStyle.inputRadio}`}>
                        <input
                          type="radio"
                          value={PRODUCT.CSTV2}
                          name="radio_product"
                          onChange={this.handleProductChange}
                          checked={product === PRODUCT.CSTV2}
                          disabled={submitting}
                        />{" "}
                        v2.0 with Codeless Explorer
                      </label>
                      <br />

                      <label className={`${FormStyle.inputRadio}`}>
                        <input
                          type="radio"
                          value={PRODUCT.CSTV2NP}
                          name="radio_product"
                          onChange={this.handleProductChange}
                          checked={product === PRODUCT.CSTV2NP}
                          disabled={submitting}
                        />{" "}
                        v2.0 without Codeless Explorer
                      </label>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          )}
        </YbModal>
      </div>
    );
  }
}

module.exports = YbNetworkCandidates;
